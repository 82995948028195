nav {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    // display: none;

    .links {
        // background: pink;
        height: 100vh;
        padding: 0 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0;

        li {
            padding: 0;
            margin: 0;
            list-style: none;
            transition: all 300ms ease-in-out;

            &.active {
                var {
                    background: #EB3352;
                    border: 1px solid #EB3352;
                }
            }

            &:hover {
                span {
                    opacity: 1;
                    pointer-events: all;
                    transform: translateX(0);
                }
            }
        }

        a {
            display: flex;
            align-items: center;
            color: #EB3352;
            text-decoration: none;
            margin: 0.25rem 0;
            transition: all 300ms ease-in-out;

            var {
                display: block;
                width: 0.5rem;
                height: 0.5rem;
                background: white;
                border: 1px solid black;
                border-radius: 50%;
                margin-right: 0.5rem;
                transition: all 300ms ease-in-out;
            }

            span {
                opacity: 0;
                font-size: 0.85rem;
                pointer-events: none;
                background: white;
                padding: 0.25rem 1rem;
                border-radius: 1rem;
                transition: all 300ms ease-in-out;
                transform: translateX(10px);
            }
        }

        .discord-icon {
            position: absolute;
            left: 1.25rem;
            margin-top: 3rem;
            background: white;
            border-radius: 50%;
            padding: 0.25rem;

            &:hover {
                    color: #EB3352;
                svg {

                    fill: #EB3352;
                }
            }
        }
    }
}