.page {
    margin-top: 2px;
}

section {
    min-height: 80vh;
    width: 100vw;
    overflow-x: hidden;
    // padding: 12rem 1rem;
    padding: calc(2em + 14vmin) 1rem;
    box-sizing: border-box;
    background: white;

    &:nth-of-type(odd){
        background: $bggray;
    }

    &.timeline {
        background-image: url('../Images/timeline.svg');
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }

    &.cover {
        background-color: #e05858;
        background-image: url(https://img.itch.zone/aW1nLzYzNzcyOTUucG5n/original/y4fll0.png);
        background-repeat: repeat;
        background-position: 100% 0;
        background-size: 100% 100%;
        background-attachment: fixed;
        color: white;
        padding: 8rem 0;
        z-index: -1;
    }

    &.breaker {
        height: 100vh;
        background: black;
        padding: 0;
        display: flex;
        align-items: center;
    }

    &.pathways {
        // background-image: url('https://images.unsplash.com/photo-1596779845727-d88eb78a1b08?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3733&q=80');
        background: orangered;
        color: white;
        background-size: cover;
    }

    &.introend{
        background: #000;
        color: $boxgray;
    }
}

.logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // outline: 1px solid red;

    img {
       max-width: 100vh;
    }

    .logo-text {
        font-family: 'Press Start 2P', cursive;
        max-width: 45ch;
        text-align: center;
        background: #1F2C50;
        padding: 1rem 2rem;
        margin: 4rem 0;
        font-size: 0.75rem;
        text-transform: uppercase;
        line-height: 1.75;
        display: none;
    }

    .start {
        margin-top: 8rem;
        color: white;
        font-family: 'Press Start 2P', cursive;
        text-transform: uppercase; 
        animation: blinker 1s linear infinite;
    }

    @keyframes blinker {
        50% {
            opacity: 0;
        }
    }
}

.t-center{
    text-align: center;
}

.v-mid {
    display: grid;
    align-items: center;
    min-height: 80vh;
}

.pathway {
    padding-top: 100%;
    position: relative;
    background: $boxgray;
    border-radius: 1rem;
    margin-bottom: 1.5rem;
    color: $text;
    background: url('../Images/pixel.png');
    // background: black;
    background-size: contain;
    background-repeat: none;
    background-size: 300% auto;

    &.path1 {
        background-position: center left;
        // background: #817799;
    }

    &.path2 {
        background-position: center center;
        // background: #EE80A7;
    }

    &.path3 {
        background-position: center right;
        // background: #F7CDAF;
    }

    .copy {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 2rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        
        h4 {
            font-size: 2rem;
            font-weight: 600;
        }
        // justify-content: flex-end;

        img {
            width: 4rem;
            height: auto;
            margin-bottom: 2rem;
        }
    }
}

.statbox {
    padding-top: 80%;
    // padding: 4rem 0;
    position: relative;
    background: $boxgray;
    background: #1F2C50;
    border-radius: 1rem;
    margin-bottom: 1.5rem;
    
    .copy {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 2rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: flex-end;
        justify-content: center;
        font-size: 1.2rem;
        color: white;
        font-weight: 500;
    }
}

.callout {
    margin-top: 4rem;
    padding: calc(1em + 5vmin);;
    border-radius: 2rem;
    background: $boxgray;

    .callout-pic {
        border-radius: 0.5rem;
        // margin-bottom: 1rem;
        overflow: hidden;
    }
}

.herobox {
    padding: 4rem 0;

    img {
        box-shadow: rgba(17, 12, 46, 0.1) 0px 48px 100px 0px;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
        border-radius: 1rem;
        margin: 2rem 0;
    }
}

.benebox {
    // background: white;
    // box-shadow: rgba(17, 12, 46, 0.1) 0px 48px 100px 0px;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    // border-radius: 0;
    // font-size: 1.25rem;
    // font-weight: 500;
    // padding-top: 100%;
    // position: relative;
    // margin-bottom: 2rem;
    // border-radius: 0.5rem;
    // border-radius: 50%;
    // border-left: 1px solid;
    // border: 2px solid white;
    // border-left: 8px solid black;

    .copy {
        border-top: 1px solid #240d19;
        padding: 2rem 0;
        // font-size: 1.75rem;
        font-size: calc(1.25em + 1.5vmin);
        font-weight: 500;
        line-height: 1.3;
        // margin-bottom: 1rem;
        // display: flex;
        background-image: linear-gradient(60deg, #EB3352, #742D52);
        color: black;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-box-decoration-break: clone;
        // position: absolute;
        // top: 0;
        // bottom: 0;
        // padding: 2rem;
        // outline: 1px solid red;
        // display: grid;
        // align-items: center;
        // justify-content: center;
        // text-align: center;

        >div {
            padding-right: 2rem;
            display: none;

            img {
                width: 2rem;
                height: auto;
                opacity: 1;
                opacity: 0.25;
            }
        }
    }
}