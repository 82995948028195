body, html {
    color: $text;
    user-select:none;
}

h1 {
    margin-bottom: 4rem;
    font-weight: bold;
    // font-size: 3rem;
    font-size: calc(1em + 4vmin);

    &.mid {
        font-size: calc(2em + 4vmin);
        background-image: linear-gradient(60deg, #EB3352, #F7CDAF);
        color: black;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-box-decoration-break: clone;
        // font-weight: 00;
    }

    &.large {
        font-size: calc(1.5em + 8vmin);
        // font-size: 7rem;
        background-image: linear-gradient(60deg, #EB3352, #F7CDAF);
        color: black;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-box-decoration-break: clone;
        // font-weight: 00;
    }
}

h2 {
    // margin-bottom: 3rem;
    font-weight: bold;
}

h4 {
    margin-bottom: 2rem;
}

p {
    font-size: 20px;
    line-height: 1.376;
    font-weight: 400;
    letter-spacing: .011em;
    // max-width: 55ch;
    // margin-right: 1rem;

    &.small {
        font-size: 1rem;
        color: #999;
    }

    &.small-dark {
        font-size: 1rem;
    }
}

blockquote {
    // font-family: ui-serif;
    font-size: 1.25rem;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 2rem;
    margin: 2rem 0;
    font-weight: bold;

    &.nomargin {
        margin-top: 0;
    }
}

strong {
    font-weight: 500;
}